import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import JobsSection from "../components/organisms/JobsSection"
import BenefitsSection from "../components/organisms/BenefitsSection"

export const query = graphql`
  {
    pageFeaturedImage: file(
      absolutePath: { glob: "**/src/images/og/featured-image-careers.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`

const CareersPage = ({ data }) => {
  return (
    <Layout headerBg="bg-white">
      <Seo
        title="Join Peak11"
        description="Grow with us!"
        metaFeaturedImage={
          data.pageFeaturedImage.childImageSharp.gatsbyImageData
        }
        bodyClass="bg-white"
      />
      <section className="bg-white pt-12 md:pt-36 pb-10 md:pb-24 relative">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="relative z-10">
            <h1 className="text-h0/m md:text-h0 mt-0.5">
              We are looking for a trailblazer.
              <br />
              <span className="font-extrabold">Are you one?</span>
            </h1>
            <div className="flex justify-between items-end text-base mt-8">
              <div className="max-w-[620px]">
                <p className="mb-0">
                  Peak11 has always created agile, custom software for people
                  with the aim of helping them and their businesses grow. This
                  effort is also found within our company culture. At Peak11, we
                  are fortunate to draw on the skills and experiences of so many
                  talented individuals. We treasure diversity as well as the
                  well-being of each member of our team. We also believe in
                  developing strengths and providing opportunities for
                  advancement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block absolute top-0 left-1/3 w-full h-full hero-repeat"></div>
      </section>
      <JobsSection
        header="Blaze ahead with Peak11"
        subHeader="Open positions"
      />
      <BenefitsSection
        header="Creature comforts that keep us going"
        subHeader="Benefits"
      />
    </Layout>
  )
}

export default CareersPage
